// import React from "react"
// import { Link } from "gatsby"
//
// import Layout from "../components/Layout"
// import Seo from "../components/Seo"
// import {Col, Row, Container} from "react-bootstrap";

const IndexPage = () => {
    return false;
    // <Layout>
    //     <Seo title="Home" description="All the information you need to teach your class"/>
    //     <Container bsPrefix={'wrap'} fluid={false}>
    //         <Row>
    //             <Col xs={10} className={'offset-1 my-5 py-5 text--center'}>
    //                 <h1>Hi there!</h1>
    //                 <p>Welcome to your new teacher's hub site.</p>
    //                 <p>A splash page between languages hasn't been created yet so please use the link below.</p>
    //                 <Link to="/en/">English Version</Link>
    //             </Col>
    //         </Row>
    //     </Container>
    // </Layout>
}

export default IndexPage
